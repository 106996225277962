<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="mb-2 details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
                    loggedUser.id }}</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(dateUpdated)="row">
                <div>
                    {{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
                </div>
            </template>

            <template v-slot:cell(changes)="row">
                <!-- Primary Information -->
                <div class="changed-section" v-if="hasValueChanged(row.item, 'description')">
                    <b>Description</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'description') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'description') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'type')">
                    <b>Type</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'type') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'type') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'industry')">
                    <b>Industry</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'industry') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'industry') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'hasParentCompany') && isSuperAdmin">
                    <b>Has Parent Company?</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'hasParentCompany') === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'hasParentCompany') === true ? 'YES' : 'NO' }}
                    </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'parentCompany') && isSuperAdmin">
                    <b>Parent Company</b>
                    <br>
                    <b :class="{ 'new-value': row.item.new.parentCompany, 'old-value': !row.item.new.parentCompany }">
                        {{
                    row.item.new.parentCompany ? row.item.new.parentCompany : '-' }} </b>
                </div>

                <div class="changed-section" v-if="hasValueChanged(row.item, 'isActive')">
                    <b>Status</b>
                    <br>
                    From <span v-if="row.item.old !== null">
                        <CompanyStatus :company="row.item.old" />
                    </span>
                    <span v-else>&nbsp;-&nbsp;</span>
                    to
                    <CompanyStatus :company="row.item.new" />
                </div>

                <!-- Address -->
                <div class="changed-section" v-if="hasValueChanged(row.item, 'address')">
                    <b>Address</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'address') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'address') }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'geoaddress', 'latitude')">
                    <b>Latitude</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.geoaddress.latitude : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.geoaddress.latitude : '0' }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'geoaddress', 'longitude')">
                    <b>Longitude</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.geoaddress.longitude : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.geoaddress.longitude : '0' }} </b>
                </div>

                <!-- Contact Details -->
                <div class="changed-section" v-if="hasValueChanged(row.item, 'contactPerson')">
                    <b>Contact Person</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'contactPerson') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'contactPerson') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'emailAddress')">
                    <b>Email</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'emailAddress') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'emailAddress') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'contactNo')">
                    <b>Contact No.</b>
                    <br>
                    From <b class="old-value numFont"> {{ getOldValue(row.item, 'contactNo') }} </b>
                    to <b class="new-value numFont"> {{ getNewValue(row.item, 'contactNo') }} </b>
                </div>

                <!-- Subscriptions -->
                <div class="changed-section" v-if="hasValueChanged(row.item, 'brand')">
                    <b>Company Code</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'brand') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'brand') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'branchCode')">
                    <b>Branch Code</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'branchCode') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'branchCode') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'noOfStorageLocations')">
                    <b>No of Storage Locations</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.noOfStorageLocations : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.noOfStorageLocations : '0' }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'noOfInactiveNodes')">
                    <b>No of Inactive Nodes</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.noOfInactiveNodes : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.noOfInactiveNodes : '0' }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'noOfUsers')">
                    <b>No of Users</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.noOfUsers : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.noOfUsers : '0' }} </b>
                </div>

                <!-- Use 2-Way Auth -->
                <div class="changed-section" v-if="hasValueChanged(row.item, 'has2WayAuth')">
                    <b>Has 2-Way Auth?</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'has2WayAuth') === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'has2WayAuth') === true ? 'YES' : 'NO' }}
                    </b>
                </div>

                <!-- Camera Options -->
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'camera', 'top')">
                    <b>Use Top Camera?</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'camera').top === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'camera').top === true ? 'YES' : 'NO' }}
                    </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'camera', 'back')">
                    <b>Use Back Camera?</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'camera').back === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'camera').back === true ? 'YES' : 'NO' }}
                    </b>
                </div>

                <!-- Company Photo -->
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'companyLogo', 'url')">
                    <b>Company Logo</b>
                    <br>
                    From <br />
                    <span v-if="!getOldValue(row.item, 'companyLogo').url">
                        <b class="old-value"> - </b>
                    </span>
                    <span v-else>
                        <b-img v-img-orientation-changer :src="getCompanyLogo(row.item.old)" alt="Responsive image"
                            thumbnail fluid class="company-logo" />
                    </span>
                    <br />
                    <br />
                    to <br />
                    <span v-if="!getNewValue(row.item, 'companyLogo').url">
                        <b class="new-value"> {{ getNewValue(row.item.new.companyLogo, 'url') }} </b>
                    </span>
                    <span v-else>
                        <b-img v-img-orientation-changer :src="getCompanyLogo(row.item.new)" alt="Responsive image"
                            thumbnail class="company-logo" />
                    </span>
                </div>

                <!-- Permissions -->
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'permissions', 'cms')">
                    <b>CMS</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'permissions').cms === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'permissions').cms === true ? 'YES' : 'NO' }}
                    </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'permissions', 'inventory')">
                    <b>Inventory</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'permissions').inventory === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'permissions').inventory === true ? 'YES' : 'NO' }}
                    </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'permissions', 'dispatch')">
                    <b>Dispatch</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'permissions').dispatch === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'permissions').dispatch === true ? 'YES' : 'NO' }}
                    </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'permissions', 'sticker')">
                    <b>Sticker Printer</b>
                    <br>
                    From <b class="old-value">
                        {{ getOldValue(row.item, 'permissions').sticker === true ? 'YES' : 'NO' }}
                    </b>
                    to <b class="new-value">
                        {{ getNewValue(row.item, 'permissions').sticker === true ? 'YES' : 'NO' }}
                    </b>
                </div>
            </template>

            <template v-slot:cell(updatedBy)="row">{{ row.item.new.updatedBy }}</template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button size="sm" @click="row.toggleDetails" variant="secondary"
                    v-b-tooltip.hover.top="'Hide Details'">
                    <i class="icon-arrow-up"></i>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Components
import CompanyStatus from '@/views/setup/company/CompanyStatus';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import _ from 'lodash';

export default {
    name: 'company-change-logs-details-view',
    components: {
        CompanyStatus
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            companyId: '',

            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        };
    },
    watch: {
        companyId: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    async mounted() {
        this.companyId = this.row.item.id;
        await this.retrieveChangeLog(this.companyId);
    },
    methods: {
        async retrieveChangeLog(companyId) {
            if (companyId) {
                let param = {
                    collection: 'companies',
                    id: companyId,
                    userId: !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },
        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, (log) => {
                const oldLog = log.old ? log.old : {};
                const newLog = log.new ? log.new : {};

                // Primary Information
                const oldDesc = oldLog.description ? oldLog.description : '';
                const newDesc = newLog.description ? newLog.description : '';
                const oldType = oldLog.type ? oldLog.type : '';
                const newType = newLog.type ? newLog.type : '';
                const oldIndustry = oldLog.industry ? oldLog.industry : '';
                const newIndustry = newLog.industry ? newLog.industry : '';
                const oldHasParentCompany = oldLog.hasParentCompany ? oldLog.hasParentCompany : '';
                const newHasParentCompany = newLog.hasParentCompany ? newLog.hasParentCompany : '';
                const oldParentCompany = oldLog.parentCompany ? oldLog.parentCompany : '';
                const newParentCompany = newLog.parentCompany ? newLog.parentCompany : '';
                const oldStatus = oldLog.isActive ? oldLog.isActive : '';
                const newStatus = newLog.isActive ? newLog.isActive : '';

                // Address
                const oldAddress = oldLog.address ? oldLog.address : '';
                const newAddress = newLog.address ? newLog.address : '';
                const oldLatitude = oldLog.geoaddress ? oldLog.geoaddress.latitude : 0;
                const newLatitude = newLog.geoaddress ? newLog.geoaddress.latitude : 0;
                const oldLongitude = oldLog.geoaddress ? oldLog.geoaddress.longitude : 0;
                const newLongitude = newLog.geoaddress ? newLog.geoaddress.longitude : 0;

                // Contact Details
                const oldContactPerson = oldLog.contactPerson ? oldLog.contactPerson : '';
                const newContactPerson = newLog.contactPerson ? newLog.contactPerson : '';
                const oldEmail = oldLog.emailAddress ? oldLog.emailAddress : '';
                const newEmail = newLog.emailAddress ? newLog.emailAddress : '';
                const oldContactNo = oldLog.contactNo ? oldLog.contactNo : '';
                const newContactNo = newLog.contactNo ? newLog.contactNo : '';

                // Subscriptions
                const oldCompanyCode = oldLog.brand ? oldLog.brand : '';
                const newCompanyCode = newLog.brand ? newLog.brand : '';
                const oldBranchCode = oldLog.branchCode ? oldLog.branchCode : '';
                const newBranchCode = newLog.branchCode ? newLog.branchCode : '';
                const oldNoOfStorageLocations = oldLog.noOfStorageLocations ? oldLog.noOfStorageLocations : 0;
                const newNoOfStorageLocations = newLog.noOfStorageLocations ? newLog.noOfStorageLocations : 0;
                const oldNoOfInactiveLocations = oldLog.noOfInactiveNodes ? oldLog.noOfInactiveNodes : 0;
                const newNoOfInactiveLocations = newLog.noOfInactiveNodes ? newLog.noOfInactiveNodes : 0;
                const oldNoOfUsers = oldLog.noOfUsers ? oldLog.noOfUsers : 0;
                const newNoOfUsers = newLog.noOfUsers ? newLog.noOfUsers : 0;

                // Has 2-Way Auth
                const oldHas2WayAuth = oldLog.has2WayAuth ? oldLog.has2WayAuth : '';
                const newHas2WayAuth = newLog.has2WayAuth ? newLog.has2WayAuth : '';

                // Camera Options
                const oldCamera = oldLog.camera ? oldLog.camera : {};
                const newCamera = newLog.camera ? newLog.camera : {};
                const oldUseTopCamera = oldCamera.top ? oldCamera.top : false;
                const newUseTopCamera = newCamera.top ? newCamera.top : false;
                const oldUseBackCamera = oldCamera.back ? oldCamera.back : false;
                const newUseBackCamera = newCamera.back ? newCamera.back : false;

                // Company Logo 
                const oldCompanyLogo = oldLog.companyLogo ? oldLog.companyLogo : '';
                const oldImgUrl = oldCompanyLogo.url ? oldCompanyLogo.url : '';
                const newCompanyLogo = newLog.companyLogo ? newLog.companyLogo : '';
                const newImgUrl = newCompanyLogo.url ? newCompanyLogo.url : '';

                // Permissions
                const oldCMS = oldLog.permissions ? oldLog.permissions.cms : '';
                const newCMS = newLog.permissions ? newLog.permissions.cms : '';
                const oldInventory = oldLog.permissions ? oldLog.permissions.inventory : '';
                const newInventory = newLog.permissions ? newLog.permissions.inventory : '';
                const oldDispatch = oldLog.permissions ? oldLog.permissions.dispatch : '';
                const newDispatch = newLog.permissions ? newLog.permissions.dispatch : '';
                const oldStickerPrinter = oldLog.permissions ? oldLog.permissions.sticker : '';
                const newStickerPrinter = newLog.permissions ? newLog.permissions.sticker : '';

                return oldDesc !== newDesc ||
                    oldType !== newType ||
                    oldIndustry !== newIndustry ||
                    oldHasParentCompany !== newHasParentCompany ||
                    oldParentCompany !== newParentCompany ||
                    oldStatus !== newStatus ||
                    oldAddress !== newAddress ||
                    oldLatitude !== newLatitude ||
                    oldLongitude !== newLongitude ||
                    oldContactPerson !== newContactPerson ||
                    oldEmail !== newEmail ||
                    oldContactNo !== newContactNo ||
                    oldCompanyCode !== newCompanyCode ||
                    oldBranchCode !== newBranchCode ||
                    oldNoOfStorageLocations !== newNoOfStorageLocations ||
                    oldNoOfInactiveLocations !== newNoOfInactiveLocations ||
                    oldNoOfUsers !== newNoOfUsers ||
                    oldHas2WayAuth !== newHas2WayAuth ||
                    oldUseTopCamera !== newUseTopCamera ||
                    oldUseBackCamera !== newUseBackCamera ||
                    oldImgUrl !== newImgUrl ||
                    oldCMS !== newCMS ||
                    oldInventory !== newInventory ||
                    oldDispatch !== newDispatch ||
                    oldStickerPrinter !== newStickerPrinter;
            });

            this.changeLogs = filteredLogs;
        },

        hasValueChanged(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        hasChangesInNestedFields(log, nestedfield, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldNestedField = oldLog[nestedfield] ? oldLog[nestedfield] : {};
            let newNestedField = newLog[nestedfield] ? newLog[nestedfield] : {};

            let oldValue = oldNestedField[fieldName] ? oldNestedField[fieldName] : "-";
            let newValue = newNestedField[fieldName] ? newNestedField[fieldName] : "-";

            return oldValue !== newValue;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let value = oldLog[fieldName] ? oldLog[fieldName] : "-";
            return value;
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};
            let value = newLog[fieldName] ? newLog[fieldName] : "-";
            return value;
        },
        getCompanyLogo(log) {
            let companyLogo = log.companyLogo ? log.companyLogo : {};
            let imgUrl = companyLogo.url ? companyLogo.url : '-';

            if (!ValidationUtil.isValidURL(imgUrl)) {
                imgUrl = '-';
            }
            return imgUrl;
        }
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}

.company-logo {
    width: 20%;
}
</style>