<template>
	<span>
		<span v-if="company.isActive === 'false'">
			<b-badge variant="secondary">Inactive</b-badge>
		</span>
		<span v-else>
			<b-badge variant="success">Active</b-badge>
		</span>
	</span>
</template>

<script>
export default {
	name: 'company-status',
	props: {
		company: {
			type: Object,
			required: true
		}
	}
};
</script>

<style></style>