// Util
import { UserUtil } from '@/utils/userutil';

// Others
import { db } from '@/config/firebase';

async function getUserById(userId) {
    const dbRef = db.collection('users').doc(userId);
    const querySnapshot = await dbRef.get();

    let user = {};
    user = {
        id: querySnapshot.id,
        ...querySnapshot.data()
    }

    return user
}

async function getUsersByCompanyId(companyId) {
    const queryRef = db.collection('users').where("companyId", "==", companyId);
    return queryRef.get().then(function (querySnapshot) {
        let users = {}
        querySnapshot.forEach(function (doc) {
            let user = doc.data();
            user['id'] = doc.id;
            // do not include superadmin account in the list of users
            if (!UserUtil.isSuperAdmin(user.emailAddress)) {
                users[doc.id] = user;
            }
        });
        return users;
    }).catch((error) => {
        return error;
    })
}

async function getNoOfActiveUsersPerCompany(companyId) {
    const query = db.collection('users')
        .where("companyId", "==", companyId)
        .where("isActive", "==", "true");
    const snapshot = await query.get();
    return snapshot.size;
}

export default {
    getUserById,
    getUsersByCompanyId,
    getNoOfActiveUsersPerCompany
}